import _ from "lodash";
import __ from "@/translation";

export const CONNECTOR_CONDITION_TAG_PREFIX = "connector_condition_";

export const NODE_CATEGORIES = Object.freeze({
  SYSTEM: "system",
  NO_CHILD: "no_child",
  ONE_CHILD: "one_child",
  MULTIPLE_CHILD: "multiple_child",
  DUMMY: "dummy"
});

export const TASK_TYPES = Object.freeze({
  VOICE: "voice",
  MESSAGING: "messaging",
  WORKFLOW: "workflow",
  CHATBOT: "chatbot",
  WHATS_APP: "whatsapp"
});

export const VARIABLE_TYPES = Object.freeze({
  STUDIO: "studio",
  STUDIO_LABEL: "Studio",
  INTERACTION: "interaction",
  INTERACTION_LABEL: "Interaction"
});

export const DROP_RULES = Object.freeze({
  LEAF_NODE: "leaf",
  NO_CHILD_NODE: NODE_CATEGORIES.NO_CHILD,
  MULTI_CHILD_NODE: NODE_CATEGORIES.MULTIPLE_CHILD,
  DUMMY_NODE: NODE_CATEGORIES.DUMMY
});

export const DEFAULT_ON_NOT_DROP_RULE = [
  DROP_RULES.NO_CHILD_NODE,
  DROP_RULES.MULTI_CHILD_NODE
  // DROP_RULES.DUMMY_NODE
];

export const getNodeConfig = node_type =>
  _.cloneDeep(_.find(NODE_TYPES, nodeType => nodeType.NODE_TYPE === node_type));

export const NODE_TYPES = Object.freeze({
  START: {
    get LABEL() {
      return __("Start");
    },
    NODE_TYPE: "start_node",
    CATEGORY: NODE_CATEGORIES.SYSTEM,
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true,
    UNIQUE_PROP: "start_node"
  },
  PROMPT: {
    get LABEL() {
      return __("Prompt Node");
    }, // used as title while rendering the node, and also as drop down option while creating new nodes
    NODE_TYPE: "prompt", // match the node_type from node_type table
    get TOOLTIP() {
      return __("Prompt");
    }, // used as popup while hovering over the icon in node palette
    CATEGORY: NODE_CATEGORIES.ONE_CHILD, // match with category of the node type from backend
    NEW_NODE_PREFIX: "prompt_", // default name prefix when creating a new node of this type
    DROP_RULES: {}, // any specific drop rules to enforce from DROP_RULES enum, detailed explanation in integration node type object below
    UNIQUE_PROP: "prompt_node" // the property in node object to look at, for any changes
  },
  INTEGRATION: {
    get LABEL() {
      return __("Integration Node");
    },
    NODE_TYPE: "integration",
    get TOOLTIP() {
      return __("Integration");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "integration_",
    DROP_RULES: {
      // all these properties are optional
      onlyOn: [], //  can only drop on a node having all these classes
      on: [], // can drop on a node having any of these classes (one or more together)
      onNot: [] // cannot drop on a node having any of these classes (one or more together)
    },
    UNIQUE_PROP: "integration_node"
  },
  TRIGGER_CAMPAIGN: {
    get LABEL() {
      return __("Trigger Campaign Node");
    },
    NODE_TYPE: "trigger_campaign",
    get TOOLTIP() {
      return __("Trigger Campaign");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "trigger_campaign_",
    DROP_RULES: {},
    UNIQUE_PROP: "trigger_campaign_node"
  },
  SEND_SMS: {
    get LABEL() {
      return __("Send SMS Node");
    },
    NODE_TYPE: "messaging_send",
    get TOOLTIP() {
      return __("Send SMS");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "send_sms_",
    DROP_RULES: {},
    UNIQUE_PROP: "messaging_node"
  },
  REPLY_SMS: {
    get LABEL() {
      return __("Reply Message Node");
    },
    NODE_TYPE: "messaging_reply",
    get TOOLTIP() {
      return __("Reply");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "reply_sms_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    UNIQUE_PROP: "messaging_node"
  },
  PROACTIVE_MESSAGE: {
    get LABEL() {
      return __("Send Message Node");
    },
    NODE_TYPE: "messaging_proactive",
    get TOOLTIP() {
      return __("Send Message");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "send_message_",
    DROP_RULES: {},
    UNIQUE_PROP: "messaging_node"
  },
  CONVERSATION_SMS: {
    get LABEL() {
      return __("Conversation Node");
    },
    NODE_TYPE: "messaging_conversation",
    get TOOLTIP() {
      return __("Conversation");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "conversation_",
    DROP_RULES: {},
    UNIQUE_PROP: "messaging_node"
  },
  VARIABLE: {
    get LABEL() {
      return __("Variable Node");
    },
    NODE_TYPE: "variable",
    get TOOLTIP() {
      return __("Variable");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "variable_",
    DROP_RULES: {},
    UNIQUE_PROP: "variable_node"
  },
  CALENDAR: {
    get LABEL() {
      return __("Calendar Node");
    },
    NODE_TYPE: "calendar",
    get TOOLTIP() {
      return __("Calendar");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "calendar_",
    DROP_RULES: {},
    UNIQUE_PROP: "calendar_node"
  },
  SEND_EMAIL: {
    get LABEL() {
      return __("Send Email Node");
    },
    NODE_TYPE: "send_email",
    get TOOLTIP() {
      return __("Send Email");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "send_email_",
    DROP_RULES: {},
    UNIQUE_PROP: "send_email_node"
  },
  OPEN_FORM: {
    get LABEL() {
      return __("Form Node");
    },
    NODE_TYPE: "open_form",
    get TOOLTIP() {
      return __("Form");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "form_",
    DROP_RULES: {},
    UNIQUE_PROP: "open_form_node"
  },
  CLOUD_STT: {
    get LABEL() {
      return __("Cloud Speech-to-text Node");
    },
    NODE_TYPE: "cloud_stt",
    get TOOLTIP() {
      return __("Cloud STT");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "cloud_stt_",
    DROP_RULES: {},
    UNIQUE_PROP: "cloud_stt_node"
  },
  PAYMENT: {
    get LABEL() {
      return __("Payment Node");
    },
    NODE_TYPE: "payment",
    get TOOLTIP() {
      return __("Payment");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "payment_",
    DROP_RULES: {},
    UNIQUE_PROP: "payment_node"
  },
  DAYTIME: {
    get LABEL() {
      return __("Daytime Node");
    },
    NODE_TYPE: "daytime",
    get TOOLTIP() {
      return __("Daytime");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "daytime_",
    DROP_RULES: {},
    UNIQUE_PROP: "daytime_node"
  },
  COMMENT: {
    get LABEL() {
      return __("Comment Node");
    },
    NODE_TYPE: "comment",
    get TOOLTIP() {
      return __("Comment");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "comment_",
    DROP_RULES: {},
    UNIQUE_PROP: "comment_node"
  },
  DISTRIBUTE: {
    get LABEL() {
      return __("Distribute Node");
    },
    NODE_TYPE: "distribute",
    get TOOLTIP() {
      return __("Distribute");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "distribute_",
    DROP_RULES: {},
    UNIQUE_PROP: "distribute_node"
  },
  WEB_SERVICE: {
    get LABEL() {
      return __("Web Service Node");
    },
    NODE_TYPE: "web_service",
    get TOOLTIP() {
      return __("Web Service");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "web_service_",
    DROP_RULES: {},
    UNIQUE_PROP: "web_service_node"
  },
  GOTO: {
    get LABEL() {
      return __("Goto Node");
    },
    NODE_TYPE: "goto",
    get TOOLTIP() {
      return __("Goto");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "goto_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    UNIQUE_PROP: "goto_node"
  },
  DATASTORE: {
    get LABEL() {
      return __("Datastore Node");
    },
    NODE_TYPE: "data_store",
    get TOOLTIP() {
      return __("Datastore");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "datastore_",
    DROP_RULES: {},
    UNIQUE_PROP: "data_store_node"
  },
  KEYWORD_FINDER: {
    get LABEL() {
      return __("Keyword Finder Node");
    },
    NODE_TYPE: "keyword_finder",
    get TOOLTIP() {
      return __("Keyword Finder");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "keyword_finder_",
    DROP_RULES: {},
    UNIQUE_PROP: "keyword_finder_node"
  },
  RECORD: {
    get LABEL() {
      return __("Record Node");
    },
    NODE_TYPE: "record",
    get TOOLTIP() {
      return __("Record");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "record_",
    DROP_RULES: {},
    UNIQUE_PROP: "record_node"
  },
  BLIND_TRANSFER: {
    get LABEL() {
      return __("Blind Transfer");
    },
    NODE_TYPE: "blind_transfer",
    get TOOLTIP() {
      return __("Blind Transfer");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "blind_transfer_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    UNIQUE_PROP: "blind_transfer_node"
  },
  BRIDGE_TRANSFER: {
    get LABEL() {
      return __("Bridge Transfer Node");
    },
    NODE_TYPE: "bridge_transfer",
    get TOOLTIP() {
      return __("Bridge Transfer");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "bridge_transfer_",
    DROP_RULES: {},
    UNIQUE_PROP: "bridge_transfer_node"
  },
  SCREENPOP_TRANSFER: {
    get LABEL() {
      return __("Screenpop Transfer Node");
    },
    NODE_TYPE: "screenpop_transfer",
    get TOOLTIP() {
      return __("Screenpop Transfer");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "screenpop_transfer_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    UNIQUE_PROP: "screenpop_transfer_node"
  },
  FIVE9_TRANSFER: {
    get LABEL() {
      return __("Five9 Transfer");
    },
    NODE_TYPE: "five9_transfer",
    get TOOLTIP() {
      return __("Five9 Transfer");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "five9_transfer_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    UNIQUE_PROP: "five9_transfer_node"
  },
  HANGUP: {
    get LABEL() {
      return __("Hangup Node");
    },
    NODE_TYPE: "hangup",
    get TOOLTIP() {
      return __("Hangup");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "hangup_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    CANNOT_CREATE_NEW: false,
    AVAILABLE_TO_ALL_PLANS: true,
    HIDE_NODE_TASK_OVERRIDE: [
      TASK_TYPES.MESSAGING,
      TASK_TYPES.WORKFLOW,
      TASK_TYPES.CHATBOT,
      TASK_TYPES.WHATS_APP
    ]
  },
  SET_VOICE: {
    get LABEL() {
      return __("Set Voice Node");
    },
    NODE_TYPE: "set_voice",
    get TOOLTIP() {
      return __("Set Voice");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "set_voice_",
    DROP_RULES: {},
    UNIQUE_PROP: "set_voice_node"
  },
  PAUSE: {
    get LABEL() {
      return __("Pause Node");
    },
    NODE_TYPE: "pause",
    get TOOLTIP() {
      return __("Pause");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "pause_",
    DROP_RULES: {},
    UNIQUE_PROP: "pause_node"
  },
  CHECK_WAITER: {
    get LABEL() {
      return __("Check Waiter");
    },
    NODE_TYPE: "check_waiter",
    get TOOLTIP() {
      return __("Check Waiter");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "check_waiter_",
    DROP_RULES: {},
    UNIQUE_PROP: "check_waiter_node"
  },
  ADD_TO_QUEUE: {
    get LABEL() {
      return __("Add To Queue");
    },
    NODE_TYPE: "add_to_queue",
    get TOOLTIP() {
      return __("Add To Queue");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "add_to_queue_",
    DROP_RULES: {},
    UNIQUE_PROP: "add_to_queue_node"
  },
  BIOMETRICS: {
    get LABEL() {
      return __("Biometrics Node");
    },
    NODE_TYPE: "biometrics",
    get TOOLTIP() {
      return __("Biometrics");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "biometrics_",
    DROP_RULES: {},
    UNIQUE_PROP: "biometrics_node"
  },
  HANGUP_HEADER: {
    get LABEL() {
      return __("SIP Header Node");
    },
    NODE_TYPE: "hangup_header",
    get TOOLTIP() {
      return __("SIP Header");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "sip_header_",
    DROP_RULES: {},
    UNIQUE_PROP: "hangup_header_node"
  },
  MENU: {
    get LABEL() {
      return __("Menu Node");
    },
    NODE_TYPE: "menu",
    get TOOLTIP() {
      return __("Menu");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "menu_",
    DROP_RULES: {},
    UNIQUE_PROP: "menu_node"
  },
  // since the below nodes are dynamically generated in frontend
  // these are available to all plans
  CONDITION_LABEL: {
    NODE_TYPE: "condition_label",
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true
  },
  SAME_NODE: {
    NODE_TYPE: "same_node",
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true
  },
  EMPTY_NODE: {
    CATEGORY: null,
    NODE_TYPE: null,
    TOOLTIP: null,
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true
  },
  DECISION: {
    get LABEL() {
      return __("Decision Node");
    },
    NODE_TYPE: "decision",
    get TOOLTIP() {
      return __("Decision");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "decision_",
    DROP_RULES: {},
    UNIQUE_PROP: "decision_node"
  },
  SLEEP: {
    get LABEL() {
      return __("Sleep Node");
    },
    NODE_TYPE: "sleep",
    get TOOLTIP() {
      return __("Sleep");
    },
    CATEGORY: NODE_CATEGORIES.NO_CHILD,
    NEW_NODE_PREFIX: "sleep_",
    DROP_RULES: {
      onlyOn: [DROP_RULES.LEAF_NODE]
    },
    CANNOT_CREATE_NEW: false,
    AVAILABLE_TO_ALL_PLANS: true,
    HIDE_NODE_TASK_OVERRIDE: [
      TASK_TYPES.VOICE,
      TASK_TYPES.MESSAGING,
      TASK_TYPES.CHATBOT,
      TASK_TYPES.WHATS_APP
    ]
  },
  PROMPT_RECORDER: {
    get LABEL() {
      return __("Prompt Recorder Node");
    },
    NODE_TYPE: "prompt_recorder",
    get TOOLTIP() {
      return __("Prompt Recorder");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "prompt_recorder_",
    DROP_RULES: {},
    UNIQUE_PROP: "prompt_recorder_node"
  },
  LINK_TASK: {
    get LABEL() {
      return __("Link Task Node");
    },
    NODE_TYPE: "link_task",
    get TOOLTIP() {
      return __("Link Task");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "link_task_",
    DROP_RULES: {},
    UNIQUE_PROP: "link_task_node"
  },
  DISCONNECT: {
    get LABEL() {
      return __("Disconnect");
    },
    NODE_TYPE: "disconnect",
    CATEGORY: NODE_CATEGORIES.SYSTEM,
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true,
    UNIQUE_PROP: "disconnect"
  },
  MACHINE_DETECTION: {
    get LABEL() {
      return __("MACHINE_DETECTION");
    },
    NODE_TYPE: "machine_detection",
    CATEGORY: NODE_CATEGORIES.SYSTEM,
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true,
    UNIQUE_PROP: "machine_detection"
  },
  BEEP_DETECTION: {
    get LABEL() {
      return __("BEEP_DETECTION");
    },
    NODE_TYPE: "beep_detection",
    CATEGORY: NODE_CATEGORIES.SYSTEM,
    CANNOT_CREATE_NEW: true,
    AVAILABLE_TO_ALL_PLANS: true,
    UNIQUE_PROP: "beep_detection"
  },
  TEXT_FORM: {
    get LABEL() {
      return __("Text Form Node");
    },
    NODE_TYPE: "text_form",
    get TOOLTIP() {
      return __("Text Form");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "text_form_",
    DROP_RULES: {},
    UNIQUE_PROP: "text_form_node"
  },
  WATSON_SPEECH: {
    get LABEL() {
      return __("Watson Speech Node");
    },
    NODE_TYPE: "watson_speech",
    get TOOLTIP() {
      return __("Watson Speech");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "watson_speech_",
    DROP_RULES: {},
    UNIQUE_PROP: "watson_speech_node"
  },
  VISUAL_FORM: {
    get LABEL() {
      return __("Visual Form Node");
    },
    NODE_TYPE: "visual_form",
    get TOOLTIP() {
      return __("Visual Form");
    },
    CATEGORY: NODE_CATEGORIES.MULTIPLE_CHILD,
    NEW_NODE_PREFIX: "visual_form_",
    DROP_RULES: {
      // all these properties are optional
      onlyOn: [], //  can only drop on a node having all these classes
      on: [], // can drop on a node having any of these classes (one or more together)
      onNot: [] // cannot drop on a node having any of these classes (one or more together)
    },
    UNIQUE_PROP: "visual_form_node",
    HIDE_NODE_TASK_OVERRIDE: [
      TASK_TYPES.MESSAGING,
      TASK_TYPES.WORKFLOW,
      TASK_TYPES.CHATBOT,
      TASK_TYPES.WHATS_APP
    ]
  },
  SUMMARIZATION: {
    get LABEL() {
      return __("AI Summary Node");
    },
    NODE_TYPE: "summarization",
    get TOOLTIP() {
      return __("AI Summary");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "ai_summary_",
    DROP_RULES: {},
    UNIQUE_PROP: "summarization_node"
  },
  KNOWLEDGE_DISTILLER: {
    get LABEL() {
      return __("AI Knowledge Node");
    },
    NODE_TYPE: "knowledge_distiller",
    get TOOLTIP() {
      return __("AI Knowledge");
    },
    CATEGORY: NODE_CATEGORIES.ONE_CHILD,
    NEW_NODE_PREFIX: "ai_knowledge_",
    DROP_RULES: {},
    UNIQUE_PROP: "knowledge_distiller_node"
  }
});

export const NODE_GROUP = Object.freeze({
  AUDIO: {
    get NAME() {
      return __("Voice");
    }, // the name of the group that should be displayed in node palette
    DISPLAY_PRIORITY: 1, // the order in which group should be displayed in node palette. 1 for highest priority, 2 for second highest and so on
    NODES: [
      NODE_TYPES.PROMPT,
      NODE_TYPES.PROMPT_RECORDER,
      NODE_TYPES.RECORD,
      NODE_TYPES.SET_VOICE,
      NODE_TYPES.CLOUD_STT,
      NODE_TYPES.WATSON_SPEECH
    ] // the node types belonging to this group
  },
  MESSAGING: {
    get NAME() {
      return __("Messaging");
    },
    DISPLAY_PRIORITY: 2,
    NODES: [
      NODE_TYPES.SEND_SMS,
      NODE_TYPES.REPLY_SMS,
      NODE_TYPES.CONVERSATION_SMS,
      NODE_TYPES.PROACTIVE_MESSAGE
    ]
  },
  WEB_SERVICE: {
    get NAME() {
      return __("Services");
    },
    DISPLAY_PRIORITY: 3,
    NODES: [
      NODE_TYPES.INTEGRATION,
      NODE_TYPES.WEB_SERVICE,
      NODE_TYPES.TRIGGER_CAMPAIGN,
      NODE_TYPES.SEND_EMAIL
    ]
  },
  TRANSFER: {
    get NAME() {
      return __("Transfer");
    },
    DISPLAY_PRIORITY: 4,
    NODES: [
      NODE_TYPES.BRIDGE_TRANSFER,
      NODE_TYPES.BLIND_TRANSFER,
      NODE_TYPES.SCREENPOP_TRANSFER
    ]
  },
  GENERATIVE_AI: {
    get NAME() {
      return __("Generative AI");
    },
    DISPLAY_PRIORITY: 5,
    NODES: [NODE_TYPES.SUMMARIZATION, NODE_TYPES.KNOWLEDGE_DISTILLER]
  },
  BACKGROUND_PROCESSING: {
    get NAME() {
      return __("Data");
    },
    DISPLAY_PRIORITY: 6,
    NODES: [
      NODE_TYPES.OPEN_FORM,
      NODE_TYPES.TEXT_FORM,
      NODE_TYPES.VARIABLE,
      NODE_TYPES.DATASTORE,
      NODE_TYPES.VISUAL_FORM,
      NODE_TYPES.HANGUP_HEADER
    ]
  },
  FLOW: {
    get NAME() {
      return __("Flow");
    },
    DISPLAY_PRIORITY: 7,
    NODES: [
      NODE_TYPES.DISTRIBUTE,
      NODE_TYPES.DECISION,
      NODE_TYPES.MENU,
      NODE_TYPES.GOTO,
      NODE_TYPES.HANGUP,
      NODE_TYPES.PAUSE,
      NODE_TYPES.LINK_TASK,
      NODE_TYPES.COMMENT,
      NODE_TYPES.CALENDAR,
      NODE_TYPES.DAYTIME,
      NODE_TYPES.SLEEP,
      NODE_TYPES.KEYWORD_FINDER
    ]
  },
  QforMe: {
    get NAME() {
      return __("QforMe");
    },
    DISPLAY_PRIORITY: 8,
    NODES: [NODE_TYPES.CHECK_WAITER, NODE_TYPES.ADD_TO_QUEUE]
  },
  SECURITY_PAYMENTS: {
    get NAME() {
      return __("Security & payments");
    },
    DISPLAY_PRIORITY: 9,
    NODES: [NODE_TYPES.BIOMETRICS, NODE_TYPES.PAYMENT]
  },
  MORE: {
    get NAME() {
      return __("More");
    },
    DISPLAY_PRIORITY: 99,
    NODES: [] // always empty
  }
});
